export let

    // VERTICAL ROLES
    v = {
        SUPER: 'superuser',
        MD: 'MD',
        U: 'USER',
        HOD: 'HOD',
        HOSD: 'HOSD',
        HOS: 'HOS',
        HOSS: 'HOSS',
        MAN: 'HOSS',
    },

    // Departments / HORIZONTAL ROLES
    ADM = 'ADMIN',
    ENG = 'ENG',
    HR = 'HR',
    OPS = 'OPS',
    SEC = 'SEC',
    SC = 'SC',

    // Sub Departments
    FIN = 'FIN',
    PROC = 'PROC',
    QCSD = 'QC',

    MD = [ADM, v.MD],
    FA = [FIN, v.MAN], //'finance_admin',
    FH = [FIN, v.MAN], //'finance_hod',
    FU = [FIN, v.U], //'finance_user',
    GA = [ADM, v.U],  //# 'general_admin',

    PA = [SC, v.MAN], //'procurement_admin',
    PU = [SC, v.U], //'procurement_user',
    PH = [SC, v.HOD], //'procurement_hod',

    EA = [ENG, v.U], //'production_admin',
    EU = [ENG, v.U], //'production_user',
    EH = [ENG, v.U], //'production_hod',
    QC = [QCSD, v.U], //'qc_admin',

    CA = [OPS, v.U], //  # 'crm_admin',
    CH = [OPS, v.U], //  # 'crm_hod',
    CU = [OPS, v.U], //  # 'crm_user',
    SCU = [SC, v.U], //  # 'supply_chain_user',
    SH = [SC, v.U], //  # 'supply_chain_hod',
    IA = [SC, v.U], //  # 'inventory_admin',
    TA = [SC, v.U], //  # 'transport_admin',
    HA = [HR, v.U], //  # 'hr_admin',
    S = v.SUPER,

    QC_ADMIN = QC,
    SYSTEM_ADMINISTRATION = [S],
    FLEET = [S, GA],
    BUDGET = [S, PA, PH, EU, EH],
    CRM = [CA, S, CH, CU, CH],
    FINANCE = [FA, S, FU, FH],
    SUPPLY_CHAIN = [PA, S, PU, PH, GA],
    INVENTORY = [...SUPPLY_CHAIN, ...FINANCE],
    ADMINISTRATION = [S, GA],
    ENGINEERING = [EU, QC, S, EU],
    HUMAN_RESOURCE = [HA, S],
    ASSET = [...SUPPLY_CHAIN, ...FINANCE],
    roles = {
        QC,
        S,
        ADMINISTRATION,
        ENGINEERING,
    },
    can = function (action, model) {
        return {
            all_menus: {
                view: [S]
            },
            requisitions: {
                md_approve: [S, MD],
                finance_approve: [S, FA, FH],
                procurement_approve: [S, PA, PH],
                hod_approve: [S, GA, CH, CA]
            },
            tools: {
                issue: [S, EU]
            },
            items: {
                qc_approve_issuance: [S, QC],
                qc_approve_goods_received: [S, QC],
                update_stock: [S, FA],
                issue: [EU, QC, S],
                qc_approve: [QC, S],
                link_product_parts: [EU, QC, S],
                link_parts_raw_materials: [EU, QC, S]
            },
            purchase_orders: {
                md_approve: [S, MD],
                finance_approve: [S, FA, FH],
                procurement_approve: [S, PA, PH],
                finance_view: [FA, FH]
            },
            purchase_quotations: {
                award_score: [S, PA, PH],
                md_approve: [S, MD]
            },
            customer_quotations: {
                md_approve: [S, MD],
                verify: [S, FA]
            },
            dispatch_requisitions: {
                md_approve: [S, MD],
                receive: [S, CA, CU, CH]
            },
            budget: {
                allocate: [S, FA],
                crm_view: [CA, S, CH]
            },
            sales_order: {
                issue_job_order: [S, CA, CH],
                receive_job_order: [S, EU]
            },
            item_requisitions: {
                approve_or_reject: [S, CA, GA, PA, PH, CH, FA, EU],
                receive_or_return: [S, EU, GA],
            },
            sales_invoice: {
                approve_or_reject: [S, FA]
            },
            budget_vote_heads: {
                allocate_budget: [S, PA, PH, CH, CA, FH, FA, EU, EH],
            },
            maintenance: {
                administer: [EU, QC, S],
            },
            management: {
                approve: [MD, S]
            }
        }[model][action] || []
    },

    view = function (dashboard) {
        return {
            main_dashboard: [S, MD],
            sales_orders: [CA, CH, CU],
            purchase_orders: [PA, PH, PU],
            assets: [GA, IA],
            fin_sales_order: [FA],
            item_issuance: [EU],
            vehicles: [TA],
            item_requisition_requests: [CU, PU, EU]
        }[dashboard] || []
    }

