import roles_users from "./roles_users";
import foreign_keys from "../foreign_keys";

export default {
    type: 'table',
    name: 'PROGRAM user',
    tab_name: 'User(s)',
    post_url: '',
    table: 'User',
    lines: [
        [
            {name: 'First Name', id: 'User.first_name', type: 'text', required: true},
            {name: 'Last Name', id: 'User.last_name', type: 'text', required: true}
        ],
        [
            {name: 'Username', id: 'User.payroll_no', type: 'text', required: true},
            {name: 'Email', id: 'User.email', type: 'text', required: true},
            {name: 'Is Active', id: 'User.active', type: 'select', ...foreign_keys.yes_no_boolean, span: 1, required: true}
        ], [
            {
                type: 'custom', span: 3, component: function (props) {
                    let {form_state} = props
                    if (['insert', 'update'].includes(form_state)) {
                        return this.populateForm({
                            lines: [
                                [{name: 'Password', id: 'User.password', type: 'password', showInTable: false, required: true}, {name: 'Confirm Password', id: 'User.confirm_password', type: 'password', showInTable: false},],
                            ]
                        }).populated_form
                    } else {
                        return null
                    }
                }
            }
        ],[],
        [{...roles_users, fkey: 'user_id', below: true, customComponent: 'table'}]
    ]
}
