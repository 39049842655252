export default {
    name: 'Mind Map',
    roles: ['superuser'],
    children: [
        {
            name: 'Mind',
            target: 'mind'
        }, {
            name: 'Mapp',
            target: 'mapp'
        }
    ]
}