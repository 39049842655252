import foreign_keys from "../../../custom/forms/foreign_keys";
import {element_id} from "../table/utilities";
import {Link} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'
import {Actions} from "../../action_utilities";
import {server_error} from "../errors";
import axios from "axios";
import element_style from "./formelements/element_style";

const preprocessor = (state, props) => {
        let {id, handleChange} = props,
            new_id = element_id(id)
        if (props.form_values[new_id] === undefined && props['defaultValue']) {
            handleChange({
                preventDefault: () => null,
                target: {
                    id: new_id,
                    value: props['defaultValue']
                }
            }, undefined)
            // handleChange(null, {[id]: props['defaultValue']})
        }
    },
    custom_component = function (props) {
        let {form_values, record = form_values, form_state} = props,
            {target_title} = this.props,
            foundPage = target_title.length && target_title.find(item => item.title === record.current_page),
            page = foundPage && foundPage.target
        return form_state === 'insert' ? null : <div>
            {page ? <Link to={page}>{record.current_page}</Link> : record.current_page}
        </div>
    },
    undo = (e, record, others) => {
        let {act, bool, model, primary_key, updateDisplayStateAndRefresh, showAlert} = others
        if (window.confirm(`Would you like to ${act} entry ${primary_key}?`)) {
            e.preventDefault()
            axios.put(`/api/v1/${model}/${primary_key}`, {'undo': true})
                .then(
                    response => {
                        showAlert(true, response.data, response.status)
                        // updateDisplayStateAndRefresh()
                    }, error => {
                        let message = server_error(error)
                        message && showAlert(true, message)
                    }
                )
        }
    },
    LogActions = Actions(record => [
        {name: 'Undo', fa: 'eye', action: (e, record, others) => undo(e, record, {act : `Undo ${record.action}`, model: 'activity_log', primary_key: record['id'], ...others}), color: 'danger'}
    ])
export const
    problem_form = (target) => ({
        type: 'table',
        name: 'Problems Form',
        target: 'Problems Form',
        post_url: '',
        table: 'problems',
        lines: [
            [{name: 'Current Page', id: 'custom', type: 'custom', component: custom_component, showInTable: true, span: 3}],
            [{name: 'Current Page', id: 'problems.current_page', type: 'text', span: 3, defaultValue: target, preprocessor, showInTable: false}],
            [{name: 'Problem Details', id: 'problems.details', type: 'textarea', span: 3, required: true}],
            [{name: 'Possible Solution (Optional)', id: 'problems.solution', type: 'textarea', span: 3}],
            [{name: 'Solved', id: 'problems.solved', ...foreign_keys.yes_no}, {name: 'User', id: 'problems.user', type: 'select', source: 'User.full_name', span: 1}],
            [{name: 'Attachment', id: 'problems.attachment', type: 'file'}, {name: 'Date Created', id: 'date_created', type: 'text', disabled: true}],
            []
        ],
    }),
    solved_problem_form = {
        ...problem_form(undefined),
        search_params: {solved: 'Yes'},
        target: 'solved_problems',
        name: 'solved_problems'
    },
    export_list = (target, options = []) => ({
        name: 'REPORT hr Export List',
        target: 'REPORT hr Export List',
        post_url: '/report',
        table: 'export',
        lines: [
            [{name: 'Search Params', id: 'export.search_params', type: 'hidden', span: 3, defaultValue: target, preprocessor}],
            [{name: 'Columns', id: 'export.columns', type: 'multiselect', options: options, all: true}],
            [foreign_keys.output],
            []
        ],

    }),
    activity_log = {
        type: 'table',
        table: 'activity_log',
        name: 'activity_log',
        tab_name: 'Activity Log',
        readonly: true,
        lines: [
            [
                {name: 'id', id: 'id', type: 'text'},
                {name: 'user', id: 'user', type: 'text', showInTable: false},
                {name: 'user', id: 'User.full_name', type: 'reference', reference: 'user', showInModal: false},
                {name: 'action', id: 'action', type: 'text'},
            ], [
                {name: 'table', id: 'table', type: 'text'},
                {name: 'record_keys', id: 'record_keys', type: 'text'},
            ], [
                {name: 'old_data', id: 'old_data', type: 'textarea', span: 3},
            ], [
                {name: 'data', id: 'data', type: 'textarea', span: 3},
            ], [
                {name: 'datetime', id: 'datetime', type: 'text'},
                {
                    name: 'Actions', type: 'custom', component: function (props) {
                        return element_style(LogActions.bind(this))(props)
                    }, showInTable: true
                }
            ]
        ]
    },
    report_log = {
        type: 'table',
        table: 'report_log',
        name: 'report_log',
        tab_name: 'Report Log',
        readonly: true,
        lines: [
            [
                {name: 'id', id: 'id', type: 'text'},
                {name: 'user', id: 'action', type: 'text', showInTable: false},
                {name: 'user', id: 'User.full_name', type: 'reference', reference: 'user'},
                {name: 'filename', id: 'filename', type: 'text'},
            ], [
                {name: 'target', id: 'target', type: 'text'},
                {name: 'output', id: 'output', type: 'text'},
            ], [
                {name: 'arguments', id: 'arguments', type: 'textarea', span: 3},
            ], [
                {name: 'datetime', id: 'datetime', type: 'text'},
            ]
        ]
    }