export default {
    name: 'Finance',
    roles: ['superuser'],
    children: [
        {
            name: 'Transactions',
            target: 'transactions',
            fa: 'fa-exchange',
        }, {
            name: 'Trial Balance',
            target: 'trial_balance',
        }, {
            name: 'Trial Balance (Long)',
            target: 'trial_balance_transactions',
        }, {
            name: 'Accounting',
            target: 'accounting',
            fa: 'fa-balance-scale',
            children: [{
                name: 'Chart of Accounts',
                target: 'customer_quotations',
                fa: 'fa-folder',
                children: [
                    {
                        name: 'Account Groups',
                        target: 'account_group',
                        fa: 'fa fa-square'
                    }, {

                        name: 'Account Categories',
                        target: 'account_category',
                        fa: 'fa fa-th-large',
                    }, {
                        name: 'Account Sub Categories',
                        target: 'account_sub_category',
                        fa: 'fa fa-th'
                    }, {
                        name: 'GL Accounts',
                        target: 'account_gl',
                        fa: 'fa-lock',
                    }
                ]
            }, {
                name: 'Transactions',
                target: 'transactions',
                fa: 'fa-exchange',
            }, {
                name: 'Journal Entries',
                target: 'journal_entries',
                fa: 'fa-link'
            }, {
                name: 'Financial Years',
                target: 'financial_year',
                fa: 'fa-calendar',
            }

            ]
        }, {
            name: 'Imprest / Petty Cash',
            target: 'petty_cash',
            fa: 'fa-file-invoice',
            children: [
                {
                    name: 'Petty Cash Requisition',
                    target: 'petty_cash_requisition_request',
                    fa: 'fa-calculator-alt',
                },
                {
                    name: 'Petty Cash Issuance',
                    target: 'petty_cash_records',
                    fa: 'fa-calculator-alt',
                }
            ]
        }, {
            name: 'Financial Reports',
            fa: 'fa-file-pdf',
            children: [
                {
                    name: 'Trial Balance',
                    target: 'trial_balance',
                }, {
                    name: 'TB Transactions',
                    target: 'trial_balance_transactions',
                }, {
                    name: 'Financial Position',
                    target: 'balance_sheet',
                }, {
                    name: 'Comprehensive Income',
                    target: 'profit_loss',
                }, {
                    name: 'Cash Flow',
                    target: 'cash_flow',
                    fa: 'fa-briefcase',
                }, {
                    name: 'Cash Flow Setup',
                    target: 'cash_flow_setup',
                    fa: 'fa-briefcase',
                }, {
                    name: 'Payments',
                    target: 'banking',
                    fa: 'fa-briefcase',

                }
            ]
        }, {
            name: 'Budget',
            target: 'budget_particulars',
            fa: 'fa-columns',
            children: [
                {
                    name: 'Particulars',
                    target: 'vote_heads',
                    fa: 'fa-dollar-sign',
                }, {
                    name: 'Budget',
                    target: 'budget',
                    fa: 'fa-shopping-bag',
                },
            ]
        }
    ]
}