export const
    hide_reference_column = true,
    show_pk_in_list = true,
    show_pk_in_table = false,
    report_target = 'MyReport',
    show_link_in_table = false,
    star_nonexistent_option = false,
    buttons_top = false,

    login_title = 'Finance and Accounting',
    login_title_small = 'Management Information System',

    icon_large = 'FAMIS',
    icon_small = 'FAM',

    top_bar_color = 'green',

    useModal = true,
    oneItemAsForm = false,
    itemsAsForm = 0 /*Can be 0 or 1 or 2 */,

    hideTableOnFormOpen = true,
    subTableBelow = false,
    showDevelopmentButton = true,
    default_icon_fa = 'certificate',
    redirect_to_login = false,
    login_type = 'Username',
    show_actions_column = false,
    show_label_pagination = true,
    domain= '@cobiz.co.ke',
    default_page = '/page/trial_balance'

export default {showDevelopmentButton, report_target, default_icon_fa, redirect_to_login, login_type, show_actions_column, show_label_pagination, domain, default_page}